
.side-nav a.active {
    color: #fff !important;
}

button.btn-application-selector {
    background-color: #2a328d;
    display: block;
    width: 100%;
    padding: 10px 35px;
    border: none;
    color: #fff;
    text-align: left;
    font-size: 1rem;
}

button.btn-application-selector span {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    vertical-align: -0.355em;
}